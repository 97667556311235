import { Card } from "@/Components/ui/card";
import { cn } from "@/lib/utils";
import { Icon } from "@iconify/react";

export default function Empty({ text, icon = "emojione-monotone:person-shrugging", className = "", children, ...rest }) {
    return (
        <div
            {...rest}
            className={cn(
                "flex flex-col justify-center w-full gap-4 border-none text-md text-primary font-semibold text-center items-center",
                className,
            )}
        >
            <Icon icon={icon} width={100} className="text-primary" />
            {text}
            {children}
        </div>
    );
}
